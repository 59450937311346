@font-face {
  font-family: lg;
  src: url("lg.ef689872.ttf") format("truetype"), url("lg.485fc3d2.woff") format("woff"), url("lg.8b27c12e.svg#lg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-family: lg !important;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1080;
  background-color: #0000;
  border: none;
  border-radius: 2px;
  outline: 0;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #fff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0%, 100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@keyframes lg-left-end {
  0%, 100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  background-color: #00000073;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  height: 47px;
  text-align: center;
  width: 50px;
  box-shadow: none;
  -o-transition: color .2s linear;
  background: none;
  border: none;
  outline: 0;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  background-color: #00000073;
  padding: 10px 40px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

#lg-counter {
  color: #999;
  vertical-align: middle;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
  display: inline-block;
}

.lg-next, .lg-prev, .lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
  -o-transition: -o-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
  -moz-transition: -moz-transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
  transition: transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  opacity: 0;
  transform-origin: 50%;
  transform: scale3d(.5, .5, .5);
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-outer .lg-thumb-outer {
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  background-color: #0d0a0a;
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
  position: absolute;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  height: 100%;
  margin-bottom: -5px;
  padding: 10px 0;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -o-transition: bottom .25s ease;
  transition: bottom .25s;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  color: #999;
  cursor: pointer;
  height: 39px;
  text-align: center;
  width: 50px;
  background-color: #0d0a0a;
  border: none;
  border-radius: 2px 2px 0 0;
  outline: 0;
  padding: 5px 0;
  font-size: 24px;
  line-height: 27px;
  position: absolute;
  top: -39px;
  right: 20px;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer .lg-toggle-thumb:hover {
  color: #fff;
}

.lg-outer .lg-video-cont {
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
  display: inline-block;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  z-index: 1080;
  cursor: pointer;
  margin-top: -30px;
  margin-left: -42px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("vimeo-play.1e49092f.png") no-repeat;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("vimeo-play.1e49092f.png") 0 -58px no-repeat;
}

.lg-outer .lg-has-html5 .lg-video-play {
  height: 64px;
  width: 64px;
  opacity: .8;
  background: url("video-play.fee54261.png") no-repeat;
  margin-top: -32px;
  margin-left: -32px;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("youtube-play.0b8fad0d.png") no-repeat;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("youtube-play.0b8fad0d.png") 0 -60px no-repeat;
}

.lg-outer .lg-video-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  height: 5px;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -o-transition: opacity 80ms ease 0s;
  background-color: #333;
  transition: opacity 80ms;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-progress-bar .lg-progress {
  height: 5px;
  width: 0;
  background-color: #a90707;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1);
  transition: transform .3s cubic-bezier(0, 0, .25, 1);
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -o-transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
  transition: left .3s cubic-bezier(0, 0, .25, 1), top .3s cubic-bezier(0, 0, .25, 1);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  transform-origin: 0 0;
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
  -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
  -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
  transition: transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
}

#lg-zoom-in:after {
  content: "";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "";
}

#lg-zoom-out {
  opacity: .5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  text-align: center;
  z-index: 1080;
  height: 10px;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  vertical-align: top;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: inset 0 0 0 2px #fff;
}

.lg-outer .lg-pager-thumb-cont {
  color: #fff;
  height: 83px;
  opacity: 0;
  width: 120px;
  -webkit-transition: opacity .15s, -webkit-transform .15s;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  -moz-transition: opacity .15s, -moz-transform .15s;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-left: -60px;
  padding: 5px;
  transition: opacity .15s, transform .15s;
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  height: 12px;
  -o-transition: box-shadow .3s ease 0s;
  width: 12px;
  background-color: #ffffff80;
  border-radius: 50%;
  transition: box-shadow .3s;
  display: block;
  box-shadow: inset 0 0 0 8px #ffffffb3;
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: inset 0 0 0 8px #fff;
}

.lg-outer .lg-caret {
  height: 0;
  vertical-align: middle;
  width: 0;
  border-top: 10px dashed;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  margin-left: -5px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.lg-fullscreen:after {
  content: "";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "";
}

.lg-outer #lg-dropdown-overlay {
  cursor: default;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  background-color: #00000040;
  transition: visibility 0s linear .18s, opacity .18s linear;
  position: fixed;
  inset: 0;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay, .lg-outer.lg-dropdown-active .lg-dropdown {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}

.lg-outer .lg-dropdown {
  text-align: left;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -moz-transition: -moz-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  list-style-type: none;
  transition: transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  position: absolute;
  top: 50px;
  right: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-dropdown:after {
  content: "";
  height: 0;
  width: 0;
  border: 8px solid #0000;
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -16px;
  right: 16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover .lg-icon, .lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  white-space: pre;
  padding: 4px 12px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  display: block;
}

.lg-outer .lg-dropdown a:hover {
  background-color: #00000012;
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  vertical-align: middle;
  margin-top: -3px;
  line-height: 1;
  display: inline-block;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  float: none;
  height: auto;
  vertical-align: middle;
  width: auto;
  margin-right: 8px;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  display: inline-block;
}

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
  height: 100%;
  width: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "";
}

.lg-outer .lg-img-rotate {
  -webkit-transition: -webkit-transform .3s cubic-bezier(.32, 0, .67, 0);
  -o-transition: -o-transform .3s cubic-bezier(.32, 0, .67, 0) 0s;
  -moz-transition: -moz-transform .3s cubic-bezier(.32, 0, .67, 0);
  padding: 0 5px;
  transition: transform .3s cubic-bezier(.32, 0, .67, 0);
  position: absolute;
  inset: 0;
}

.lg-rotate-left:after {
  content: "";
}

.lg-rotate-right:after {
  content: "";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-hor:after {
  content: "";
}

.lg-flip-ver:after {
  content: "";
}

.lg-group:after, .lg-group:before {
  content: "";
  line-height: 0;
  display: table;
}

.lg-group:after {
  clear: both;
}

.lg-outer {
  z-index: 1050;
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  outline: 0;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-inner {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-item {
  background: url("loading.0eae1d3a.gif") center no-repeat;
  display: none !important;
}

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  height: 50%;
  width: 1px;
  margin-right: -1px;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  padding: 0 5px;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  background-color: #000;
  transition: opacity .15s;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  opacity: 1;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -o-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

/*# sourceMappingURL=index.6dc728df.css.map */
